import { on } from "events";

export async function playVideo(video: HTMLVideoElement) {
    return new Promise((resolve, reject) => {
        video.currentTime = 0;
        video.play();
        video.classList.add('playing');
        const onEnd = () => {
            resolve(video);
            video.currentTime = 0;
            video.removeEventListener('ended', onEnd);
            video.classList.remove('playing');
        }
        video.addEventListener('ended', onEnd);
    });
}

export async function sleep(ms: number) {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, ms);
    });
}

const updateAppHeight = () => {
    document.documentElement.style.setProperty("--app-height", `${window.innerHeight}px`)
}
window.addEventListener("resize", updateAppHeight)
updateAppHeight()