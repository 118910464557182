import React, { useEffect, useState } from 'react';
import './App.css';
import { playVideo, sleep } from './Util';
import { JsxElement } from 'typescript';

enum GameState { MENU, QUESTION, ANSWER, GAME_OVER }

const clips = [
  "clips/clip-1.mov",
  "clips/clip-2.mov",
  "clips/clip-3.mov",
  "clips/clip-4.mov"
];

export const App = () => {

  const [gameState, setGameState] = useState(GameState.MENU);
  const [currentSequence, setCurrentSequence] = useState([] as number[]);
  const [userSequence, setUserSequence] = useState([] as number[]);
  const [highScore, setHighScore] = useState(JSON.parse(window.localStorage.getItem("highScore") || "0"));
  const [sequenceStep, setSequenceStep] = useState(-1);

  async function start() {
    setCurrentSequence([]);
    setUserSequence([]);
    nextLevel([]);
  }

  async function nextLevel(currentSequence: number[]) {
    const videos = document.querySelectorAll('video');
    const nVideos = videos.length;

    const newSequence = [...currentSequence, Math.floor(Math.random() * nVideos)]
    setCurrentSequence(newSequence);
    setUserSequence([]);
    setGameState(GameState.QUESTION);

    // play videos according to random sequence
    for (let i=0; i<newSequence.length; i++) {
      setSequenceStep(i);
      await playVideo(videos[newSequence[i]]);
      await sleep(100);
    }
    setSequenceStep(-1);

    setGameState(GameState.ANSWER);
  }

  async function userSelect(iVideo: number) {
    if (gameState !== GameState.ANSWER) {
      return;
    }
    const newSequence = [...userSequence, iVideo];
    setUserSequence(newSequence);
    const playing = playVideo(document.querySelectorAll('video')[iVideo]);

    const correct = newSequence.reduce((correct, e, i) => correct && e === currentSequence[i], true);
    if (!correct) {
      setGameState(GameState.GAME_OVER);
      await playing;
      setGameState(GameState.MENU);
      const newHighScore = Math.max(currentSequence.length - 1, highScore);
      setHighScore(newHighScore);
      window.localStorage.setItem("highScore", JSON.stringify(newHighScore));
      await sleep(500);
      return;
    }
    if (newSequence.length === currentSequence.length) {
      setGameState(GameState.QUESTION);
      await playing;
      setUserSequence([]);
      setTimeout(() => nextLevel(currentSequence), 500);
    }
  }

  function resetHighScore() {
    setHighScore(0);
    window.localStorage.setItem("highScore", JSON.stringify(0));
  }

  return <div className="game">
    <div className="videos">
      {clips.map((clip, i) => 
       <video key={clip} src={clip + "#t=0.0001"} onClick={() => userSelect(i)} playsInline preload='true'/>
      )}
    </div>
    <div className="answer">
      {new Array(currentSequence.length).fill(0).map((e, i) => {
        const statusClass = i > userSequence.length - 1 ? 'unanswered' 
          : userSequence[i] === currentSequence[i] ? 'correct' : 'wrong';
        return <div key={i} className={`answer-box ${statusClass}`} data-playing={sequenceStep == i}/>
      })}
      <div className="high-score" onDoubleClick={() => resetHighScore()}>Najlepszy wynik: {highScore}</div>
    </div>
    {gameState === GameState.MENU && 
      <div className="menu">
        <button onClick={() => start()}>Nowa Gra</button>
      </div>
    }
  </div>;
}

export default App;
